#leftSideBarMenu {
  /*
 * Sidebar
 */

  &.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 48px 0 0;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    /* Hide scrollbar for Mozilla */
    scrollbar-width: none;
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .accordion-button.bg-app::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion-button.bg-app {
      padding: 0.5rem 1rem !important;
    }

    button {
      color: white;
      border: none;
      cursor: pointer;
      background-color: #0b1e2f;
      text-align: justify;
    }

    .nav-link {
      font-weight: 500;
      color: #ffffff;

      .feather {
        margin-right: 4px;
        color: #727272;
      }

      &.active {
        background-color: #106ba3 !important;
      }

      &:hover:not(.active) {
        color: #106ba3;
      }
    }

    .bg-app {
      background-color: #0a1435 !important;
    }
  }

  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }

  .sidebar-sticky {
    background-color: #0a1435;
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    scrollbar-width: none;
  }

  .sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}